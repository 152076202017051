import { On3IconForum } from '@on3/icons';
import clsx from 'clsx';
import { FanSiteAvatar } from 'components/Image/FanSiteAvatar';
import { Text } from 'components/UI/Typography/Text';

import styles from './AltFanSiteItem.module.scss';
import { IFanSiteItem } from './FanSiteItem';

export const AltFanSiteItem = ({ data, isFavoriteTeam }: IFanSiteItem) => {
  const { organization, url = '/', siteName, forum } = data || {};
  const siteUrl = url?.endsWith('/') ? url : url + '/' || '/';

  return (
    <li
      className={clsx(styles.fanitem, {
        [styles.favoriteTeam]: isFavoriteTeam,
      })}
    >
      <a className={styles.logo} href={siteUrl}>
        <FanSiteAvatar
          height={30}
          name={organization?.name ?? 'default'}
          slug="-icon-full.svg"
          width={30}
        />
      </a>
      <a className={styles.fanWrapper} href={siteUrl}>
        <Text className={styles.fanText}>{siteName}</Text>
        <Text className={styles.team} variant="subtitle2">
          {organization?.name}
        </Text>
      </a>
      {forum?.url && (
        <a href={forum?.url}>
          <Text className={styles.forum}>
            <On3IconForum height={14} width={14} />
          </Text>
        </a>
      )}
    </li>
  );
};
