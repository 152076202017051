import { On3IconCollegeFootballPlayoff, On3IconRankings } from '@on3/icons';
import { TextLink } from 'components/UI/Link/TextLink';

import styles from './TrendingNav.module.scss';

const menuData = [
  {
    key: 1,
    text: 'College Football Playoff',
    title: 'CFP',
    href: '/category/college-football-playoff/news/',
    icon: <On3IconCollegeFootballPlayoff />,
  },
  {
    key: 2,
    text: 'Andy & Ari On3',
    title: 'Andy & Ari On3',
    href: '/shows/andy-ari-on3-2/',
    icon: (
      <img
        alt="Andy & Ari On3"
        src="https://on3static.com/cdn-cgi/image/quality=100,width=18,height=18,fit=cover,gravity=0.5x0.5/uploads/assets/711/348/348711.png"
      />
    ),
  },
  {
    key: 3,
    text: 'High School Rankings',
    title: 'High School Rankings',
    href: '/high-school/rankings/football/national/',
    icon: <On3IconRankings />,
  },
];

export const TrendingNav = () => {
  return (
    <div className={styles.menublock}>
      <ul className={styles.trendingMenu}>
        {menuData.map((item) => {
          return (
            <li className={styles.menuItem} key={item.key}>
              <TextLink
                className={styles.link}
                data-title={item.title}
                href={item.href}
                underline="none"
                variant="subtitle1"
              >
                {item.icon}
                <span className={styles.fullText}>{item.text}</span>
              </TextLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
