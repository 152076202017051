import { SVGProps } from 'react';

const SvgX = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#E53935" d="M8.1 9 0 .9.9 0 9 8.1z" />
    <path fill="#E53935" d="M9 .9.9 9 0 8.1 8.1 0z" />
  </svg>
);

export default SvgX;
