import type { RpmPickListPredictionAccuraciesRelatedPagedData } from '@on3/api';
import { On3IconRpmLogo } from '@on3/icons';
import { TextLink } from 'components/UI/Link/TextLink';
import { Text } from 'components/UI/Typography/Text';

import styles from './RpmModule.module.scss';
import { RpmModuleItem } from './RpmModuleItem';

export const RpmModule = ({
  list,
}: RpmPickListPredictionAccuraciesRelatedPagedData) => {
  return (
    <div className={styles.rpmContainer}>
      {list && (
        <section className={styles.rpmWrapper}>
          <header className={styles.header}>
            <On3IconRpmLogo height={19} width={50} />
            <Text className={styles.rpmTitle} variant="subtitle1">
              Recruiting Prediction Machine
            </Text>
          </header>
          <ul className={styles.predictionListWrapper}>
            {list?.map((prediction, index) => {
              return (
                <RpmModuleItem
                  key={`${prediction.player?.key}-${index}`}
                  prediction={prediction}
                />
              );
            })}
          </ul>
          <footer className={styles.footer}>
            <TextLink
              className={styles.viewAll}
              href={`/db/expert-predictions/football/all/`}
              underline="none"
              variant="subtitle1"
            >
              Rpm Predictions
            </TextLink>
          </footer>
        </section>
      )}
    </div>
  );
};
