import type { SiteResponse } from '@on3/api';
import { useAuth } from '@on3/ui-lib/index';
import { SideModuleWrapper } from 'components/UI/Layout/Containers/Sidebar/SideModuleWrapper';
import { TextLink } from 'components/UI/Link/TextLink';

import { AltFanSiteItem } from './AltFanSiteItem';
import styles from './FanSiteList.module.scss';

interface IFanSiteListProps {
  list: SiteResponse[] | undefined;
}
// volquest, wolverine, blue and gold, ksr, bamainsider, inside texas, nebraska, warchant
const featuredSites = [373, 80, 363, 24, 72, 73, 370, 372];

export const FanSiteList = ({ list = [] }: IFanSiteListProps) => {
  const { user } = useAuth();
  const defaultFeaturedList: SiteResponse[] | undefined = [];
  const userFeaturedList: SiteResponse[] | undefined = [];
  const userSiteKeys = user?.si;

  featuredSites
    .filter((f) => !userSiteKeys?.includes(f))
    .map((f) => {
      const team = list?.find((t) => t.key === f && !userSiteKeys?.includes(f));

      if (!team) {
        return null;
      }

      return defaultFeaturedList.push(team);
    });

  userSiteKeys?.map((f) => {
    const team = list?.find((t) => t.key === f);

    if (!team) {
      return null;
    }

    return userFeaturedList.push(team);
  });

  const featuredList = [...userFeaturedList, ...defaultFeaturedList];

  return (
    <SideModuleWrapper title="Fan Sites">
      <ul className={styles.list}>
        {featuredList.map((site: SiteResponse, index) => {
          const isFavoriteTeam = user?.si?.includes(site.key);

          return (
            <AltFanSiteItem
              data={site}
              isFavoriteTeam={isFavoriteTeam}
              key={site.key}
            />
          );
        })}
      </ul>

      <footer className={styles.footer}>
        <TextLink
          className={styles.viewAll}
          href={`/teams/`}
          underline="none"
          variant="subtitle1"
        >
          Fan Sites
        </TextLink>
      </footer>
    </SideModuleWrapper>
  );
};
