import type {
  HeadlineResponse,
  NilHundredResponseNil100FilterResponseRelatedPagedData,
  PlayerItemResponsePagedData,
  RpmPickListPredictionAccuraciesRelatedPagedData,
  TeamRankingResponseRelatedTeamRankingResponseRelatedPagedData,
  UserProfileResponseRecord,
} from '@on3/api';
import { useSite } from '@on3/ui-lib/src/contexts/SiteProvider';
import { MobileMPU } from 'components/Ads/MobileMPU';
import { MPU } from 'components/Ads/MPU';
import { TopRankings as CollegeRankings } from 'components/College/Modules/TopRankings/TopRankings';
import { RpmModule } from 'components/Db/RpmModule/RpmModule';
import { CoverList } from 'components/Headlines/ArticleCover/CoverList';
import { FeedList } from 'components/Headlines/ArticleFeed/FeedList';
import { FeedListAlternate } from 'components/Headlines/ArticleFeedAlternate/FeedListAlternate';
import { TopRankings as HighSchoolRankings } from 'components/HighSchool/Modules/TopRankings/TopRankings';
import { HomeLayout } from 'components/Layouts/HomeLayout';
import { SignUp } from 'components/Newsletter/SignUp';
import { Nil100Widget } from 'components/Nil/Nil100Widget';
import { Connect } from 'components/Social/Connect';
import { On3LogoAlt } from 'components/Svg/On3LogoAlt';
import { FanSiteList } from 'components/Teams/FanSiteList';
import { LatestCommits } from 'components/Teams/LatestCommits/LatestCommits';
import { Text } from 'components/UI/Typography/Text';
import { FeaturedWritersList } from 'components/Writers/Featured/FeaturedWritersList';
import { on3Data } from 'store/mocks/Connect.mock';
import { useAds } from 'utils/hooks/useAds';

import { removeDuplicateArticles } from './articleHelper';
import styles from './NetworkHome.module.scss';

export interface INetworkPageProps {
  featuredArticles?: HeadlineResponse[];
  collegeSportsArticles?: HeadlineResponse[];
  footballRecruitingArticles?: HeadlineResponse[];
  collegeBusinessArticles?: HeadlineResponse[];
  featuredAuthors?: UserProfileResponseRecord[];
  nilHundred?: NilHundredResponseNil100FilterResponseRelatedPagedData;
  latestCommits?: PlayerItemResponsePagedData;
  highSchoolRankings?: TeamRankingResponseRelatedTeamRankingResponseRelatedPagedData;
  collegeRankings?: TeamRankingResponseRelatedTeamRankingResponseRelatedPagedData;
  hsArticles?: HeadlineResponse[];
  cfbArticles?: HeadlineResponse[];
  rpmModule: RpmPickListPredictionAccuraciesRelatedPagedData;
}

const sportViewAll = [
  {
    url: 'category/football/news/',
    text: 'View All Football',
    title: 'Football News',
  },
  {
    url: 'category/basketball/news/',
    text: 'View All Basketball',
    title: 'Basketball News',
  },
];

const recruitingViewAll = [
  {
    url: 'category/football-recruiting/news/',
    text: 'View All Football Recruiting',
    title: 'Football Recruiting News',
  },
  {
    url: 'category/basketball-recruiting/news/',
    text: 'View All Basketball Recruiting',
    title: 'Basketball Recruiting News',
  },
];

const businessViewAll = [
  {
    url: 'nil/news/',
    text: 'View All NIL',
    title: 'NIL News',
  },
];
const hsViewAll = [
  {
    url: 'high-school/news/',
    text: 'View All High School News',
    title: 'High School News',
  },
];
const cfpViewAll = [
  {
    url: 'category/college-football-playoff/news/',
    text: 'View All College Football Playoff',
    title: 'College Football Playoff',
  },
];

export const NetworkHome = ({
  featuredArticles,
  collegeSportsArticles,
  footballRecruitingArticles,
  collegeBusinessArticles,
  featuredAuthors,
  nilHundred,
  latestCommits,
  highSchoolRankings,
  collegeRankings,
  hsArticles,
  cfbArticles,
  rpmModule,
}: INetworkPageProps) => {
  const { siteUrls } = useSite();

  const teamSites = siteUrls?.filter(
    (site) => site.isTeam && site.live && site.inIndex,
  );
  const { list } = nilHundred || {};

  const coverArticles = featuredArticles?.slice(0, 3);
  const coverKeys = coverArticles?.map((article) => article.key);

  const collegeSports = removeDuplicateArticles({
    list: collegeSportsArticles,
    keys: coverKeys,
  })?.slice(0, 5);

  const footballRecruiting = removeDuplicateArticles({
    list: footballRecruitingArticles,
    keys: coverKeys,
  })?.slice(0, 5);

  const collegeBusiness = removeDuplicateArticles({
    list: collegeBusinessArticles,
    keys: coverKeys,
  })?.slice(0, 5);

  useAds({ pageType: 'home' });

  return (
    <>
      <div className={styles.topcontainer}>
        {!!coverArticles?.length && (
          <CoverList list={coverArticles.slice(0, 3)} mode="dark" />
        )}
      </div>
      <HomeLayout>
        <Text className={styles.metaText}>
          <On3LogoAlt className={styles.metaIcon} />
          Delivering trusted college and high school sports news, analysis,
          data, and insights to fans, athletes, schools, and brands
        </Text>
        <section className={styles.feed}>
          {!!collegeSports?.length && (
            <FeedList
              list={collegeSports.slice(0, 5)}
              section="College Sports"
              viewAllLinks={sportViewAll}
            />
          )}
          <MobileMPU id={'mobile_mpu_top'} incontent />
          {!!footballRecruiting?.length && (
            <FeedList
              list={footballRecruiting.slice(0, 5)}
              section="Recruiting"
              viewAllLinks={recruitingViewAll}
            />
          )}
          {!!collegeBusiness?.length && (
            <FeedList
              list={collegeBusiness.slice(0, 5)}
              section="NIL"
              viewAllLinks={businessViewAll}
            />
          )}
          {!!hsArticles?.length && (
            <FeedList
              list={hsArticles.slice(0, 5)}
              section="High School"
              viewAllLinks={hsViewAll}
            />
          )}
        </section>
        <aside className={styles.sidebarright}>
          <MPU id={'mpu_top'} />
          {highSchoolRankings && (
            <HighSchoolRankings rankings={highSchoolRankings} />
          )}
          {collegeRankings && <CollegeRankings rankings={collegeRankings} />}
          {!!list?.length && <Nil100Widget list={list?.slice(0, 5)} />}
          {!!rpmModule?.list?.length && (
            <RpmModule list={rpmModule?.list?.slice(0, 3)} />
          )}
          <MPU className={styles.sticky} id={'mpu_middle'} />
          <MobileMPU id={'mobile_mpu_middle'} incontent />
          {!!cfbArticles?.length && (
            <FeedListAlternate
              list={cfbArticles?.slice(0, 5)}
              section="College Football Playoff"
              viewAllLinks={cfpViewAll}
            />
          )}
        </aside>
        <aside className={styles.sidebarleft}>
          <FanSiteList list={teamSites} />
          {latestCommits?.list?.length && (
            <LatestCommits list={latestCommits?.list} />
          )}
          <Connect list={on3Data} title="On3 Connect" />
          {!!featuredAuthors?.length && (
            <FeaturedWritersList
              buttonText="View all"
              list={featuredAuthors}
              title="On3 Experts"
            />
          )}
        </aside>
      </HomeLayout>
      <SignUp />
    </>
  );
};
