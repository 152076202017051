import { SVGProps } from 'react';

const SvgCollegeFootballPlayoff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={18}
    fill="none"
    viewBox="0 0 13 18"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#EBD05C"
        d="M5.827 0S0 1.392 0 6.448c0 0 .087 3.94 5.462 6.618 0 0-5.876-4.562-3.408-9.36 0 0 1.2-2.378 3.773-3.704V0Z"
      />
      <path
        fill="#62676C"
        d="M5.824 0s-4.781 2.344-4.34 6.319c0 0 .651-3.785 4.348-5.148L5.824 0Z"
      />
      <path
        fill="#EBD05C"
        d="M6.673 0S12.5 1.392 12.5 6.448c0 0-.087 3.94-5.462 6.618 0 0 5.876-4.562 3.408-9.36 0-.001-1.2-2.378-3.773-3.706Z"
      />
      <path
        fill="#62676C"
        d="M6.676 0s4.756 2.32 4.316 6.294c0 0-.627-3.76-4.324-5.123L6.676 0Z"
      />
      <path
        fill="#fff"
        d="M6.039 1.188S2.21 1.915 1.481 6.32c0 0-.33 4.036 4.558 7.27V1.188ZM6.464 1.188s3.829.727 4.558 5.132c0 0 .33 4.036-4.558 7.27V1.188Z"
      />
      <path
        fill="#62676C"
        d="M7.525 5.167H4.888v.462h2.637v-.462ZM7.525 6.206H4.888v.461h2.637v-.461ZM7.525 7.245H4.888v.46h2.637v-.46ZM7.525 8.285H4.888v.461h2.637v-.461ZM.25 8.362 3.88 20H4.96l-3.158-9.006S1.05 9.978.25 8.362H.25ZM2.793 11.964 5.188 20h.804v-5.82s-2.212-1.246-3.201-2.216h.002ZM12.333 8.362 8.705 20h-.916l2.991-9.006s.754-1.016 1.555-2.632h-.002ZM9.79 11.964 7.563 20h-.304l1.37-7.104s.175.038 1.164-.932h-.001Z"
      />
      <path
        fill="#EBD05C"
        d="M8.38 13.066 7.06 20h-.637l.166-5.82s.803-.492 1.792-1.114H8.38Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h12.5v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCollegeFootballPlayoff;
