import {
  CategoryTypes,
  OrganizationType,
  rdbV1RecruitmentsLatestRpmPicksList,
  rdbV2LatestList,
  rdbV2Nil100List,
  v1CategoriesFeaturedHeadlinesDetail,
  v1CategoriesHeadlinesDetail2,
  v1FeaturedWritersDetail,
  v1SitesHeadlinesForCategoryTypeDetail,
  v1TeamRankingsRankingsList,
  v2DealsList,
} from '@on3/api';
import { withAuthentication } from '@on3/ui-lib/src/hocs/withAuthentication';
import { withValidUrl } from '@on3/ui-lib/src/hocs/withValidUrl';
import { getFulfilledValue, proxyApi } from '@on3/ui-lib/utils/api';
import type { AxiosError } from 'axios';
import { INetworkPageProps, NetworkHome } from 'components/Home/NetworkHome';
import { PageWrapper } from 'components/Layouts/PageWrapper';
import { TrendingNav } from 'components/Menu/TrendingNav';
import type { GetServerSideProps } from 'next';

const title = 'On3.com | The Best of College and High School Sports';
const description = `On3 is the best resource for college and high school sports, recruiting, and NIL. Find all of your favorite team's information or browse our advanced player database.`;

const NetworkHomePage = (props: INetworkPageProps) => {
  const canonical = 'https://www.on3.com/';

  return (
    <PageWrapper
      {...props}
      canonical={canonical}
      description={description}
      title={title}
    >
      <TrendingNav />
      <NetworkHome {...props} />
    </PageWrapper>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const siteData = await withValidUrl.isValid(ctx);
  const userData = await withAuthentication.isAuth(ctx);

  if (!siteData) {
    return {
      notFound: true,
    };
  }

  if (!siteData) {
    return {
      notFound: true,
    };
  }

  try {
    const CollegeHeadlinesParams = {
      type: CategoryTypes.Sport,
      siteKey: 44,
    };
    const tpHeadlinesParams = {
      category: 89543,
    };
    const fbRecHeadlinesParams = {
      category: 94,
    };
    const collegeBusinessHeadlinesParams = {
      category: 130,
    };
    const highSchoolHeadlinesParams = {
      category: 556984,
    };
    const cfpHeadlinesParams = {
      category: 557924,
    };
    const timeOut = {
      timeout: 2000,
    };

    const rankingsParams = {
      sportKey: 1,
      year:
        siteData?.defaultYears?.footballSeasonYear || new Date().getFullYear(),
      limit: 10,
    };

    const [
      featuredArticles,
      collegeSportsArticles,
      footballRecruitingArticles,
      collegeBusinessArticles,
      featuredAuthors,
      nilHundred,
      nilDealsList,
      latestCommits,
      tpArticles,
      hsArticles,
      rpmModule,
      highSchoolRankings,
      collegeRankings,
      cfbArticles,
    ] = await Promise.allSettled([
      v1CategoriesFeaturedHeadlinesDetail(proxyApi, 8, timeOut),
      v1SitesHeadlinesForCategoryTypeDetail(proxyApi, CollegeHeadlinesParams),
      v1CategoriesHeadlinesDetail2(proxyApi, fbRecHeadlinesParams),
      v1CategoriesHeadlinesDetail2(proxyApi, collegeBusinessHeadlinesParams),
      v1FeaturedWritersDetail(proxyApi, 44),
      rdbV2Nil100List(proxyApi, {}),
      v2DealsList(proxyApi, {}),
      rdbV2LatestList(proxyApi, {}),
      v1CategoriesHeadlinesDetail2(proxyApi, tpHeadlinesParams),
      v1CategoriesHeadlinesDetail2(proxyApi, highSchoolHeadlinesParams),
      rdbV1RecruitmentsLatestRpmPicksList(proxyApi, {}),
      v1TeamRankingsRankingsList(proxyApi, {
        ...rankingsParams,
        orgType: OrganizationType.HighSchool,
      }),
      v1TeamRankingsRankingsList(proxyApi, {
        ...rankingsParams,
        orgType: OrganizationType.College,
      }),
      v1CategoriesHeadlinesDetail2(proxyApi, cfpHeadlinesParams),
    ]);

    return {
      props: {
        pageType: 'home',
        siteData: siteData || [],
        userData: userData || {},
        featuredArticles: getFulfilledValue(featuredArticles),
        collegeSportsArticles: getFulfilledValue(collegeSportsArticles),
        footballRecruitingArticles: getFulfilledValue(
          footballRecruitingArticles,
        ),
        collegeBusinessArticles: getFulfilledValue(collegeBusinessArticles),
        featuredAuthors: getFulfilledValue(featuredAuthors),
        nilHundred: getFulfilledValue(nilHundred),
        nilDealsList: getFulfilledValue(nilDealsList),
        latestCommits: getFulfilledValue(latestCommits),
        tpArticles: getFulfilledValue(tpArticles),
        hsArticles: getFulfilledValue(hsArticles),
        rpmModule: getFulfilledValue(rpmModule),
        highSchoolRankings: getFulfilledValue(highSchoolRankings),
        collegeRankings: getFulfilledValue(collegeRankings),
        cfbArticles: getFulfilledValue(cfbArticles),
      },
    };
  } catch (error) {
    const err: AxiosError<unknown> = error as AxiosError<unknown>;

    console.log(err.message);

    return { props: {} };
  }
};

export default withValidUrl(NetworkHomePage);
