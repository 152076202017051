import { SVGProps } from 'react';

const SvgFlippedPrediction = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M20.042 8.671V28.48L9.967 18.702l10.075-10.03Z" fill="#E1E6EE" />
    <path
      d="M.042 8.671V28.48l10.075-9.777L.042 8.672Zm1.855 4.864 5.112 5.116-5.112 4.965V13.535Z"
      fill="#A1B1C8"
    />
    <path
      d="M13.877 7h6.015V.97s0-.962-1.153-.962c0 0-1.003 0-1.003.76v2.786S14.93.009 9.366.009c0 0-5.164-.406-8.923 4.306 0 0-.702.76-.05 1.469 0 0 .551.608 1.654-.405 0 0 2.206-3.293 7.218-3.293 0 0 3.71-.608 7.168 2.989h-2.607s-.55.05-.55.86c0 .051-.051 1.065.6 1.065Z"
      fill="#062A30"
    />
  </svg>
);

export default SvgFlippedPrediction;
