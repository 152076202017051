import { SVGProps } from 'react';

const SVGRankings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="currentColor"
    viewBox="0 0 14 14"
    {...props}
  >
    <path
      fill="#fff"
      d="M7.933 14h-2.8V4.2h2.8V14Zm5.6 0h-2.8V0h2.8v14ZM2.8 14H0V8.4h2.8V14Z"
    />
  </svg>
);
export default SVGRankings;
