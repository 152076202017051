import type { UserProfileResponseRecord } from '@on3/api';
import { Text } from 'components/UI/Typography/Text';

import { FeaturedWriter } from './FeaturedWriter';
import styles from './FeaturedWriter.module.scss';

interface IFeaturedWritersListProps {
  list: UserProfileResponseRecord[];
  title?: string;
  buttonText?: string;
}

export const FeaturedWritersList = ({
  list,
  title = 'Featured Writers',
}: IFeaturedWritersListProps) => {
  return (
    <div className={styles.listContainer}>
      <header>
        <Text className={styles.title} component="h2">
          {title}
        </Text>
      </header>
      <ul className={styles.listWrapper}>
        {list.map((feature) => {
          return (
            <li key={feature.key}>
              <FeaturedWriter {...feature} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
