import { ArrowDownward } from '@mui/icons-material';
import clsx from 'clsx';
import { Text } from 'components/UI/Typography/Text';
import type { HTMLProps } from 'react';

import styles from './TrendArrow.module.scss';

interface ITrendArrowProps extends HTMLProps<HTMLDivElement> {
  dollarValue?: number | string;
  percentChange?: number | string;
}
export const TrendArrow = ({
  className,
  dollarValue,
  percentChange,
}: ITrendArrowProps) => {
  const isTrendingUp = percentChange && +percentChange > 0;

  return (
    <Text
      className={clsx(
        className,
        styles.trendArrow,
        isTrendingUp ? styles.positive : styles.negative,
      )}
      component="span"
    >
      <ArrowDownward className={styles.arrow} />
      {dollarValue && <span className={styles.dollarValue}>{dollarValue}</span>}
      {percentChange && dollarValue && (
        <span className={styles.percentChange}>({percentChange}%)</span>
      )}
      {percentChange && !dollarValue && (
        <span className={styles.percentChange}>{percentChange}%</span>
      )}
    </Text>
  );
};
