import clsx from 'clsx';
import { Text } from 'components/UI/Typography/Text';
import { useMemo } from 'react';

import styles from './ConfidenceBar.module.scss';

interface IConfidenceBarProps {
  confidence?: number | null;
  /* default maintains size. condensed has a smaller look for mobile, condensedOnly keeps condensed view at all times */
  variant?: 'default' | 'condensed' | 'condensedOnly';
  /*fades confidence bar to light gray*/
  fade?: boolean;
}

export const ConfidenceBar = ({
  confidence = 0,
  variant = 'default',
  fade,
}: IConfidenceBarProps) => {
  const confidenceStyle = useMemo(() => {
    const barWidth = confidence ? 100 - confidence : 100;
    const barLeft = confidence;

    return { width: `${barWidth}%`, left: `${barLeft}%` };
  }, [confidence]);

  return (
    <div
      className={clsx(styles.confidenceItem, {
        [styles.condensed]: variant === 'condensed',
        [styles.condensedOnly]: variant === 'condensedOnly',
      })}
    >
      <Text
        className={styles.confidenceLabel}
        component="span"
        variant="subtitle1"
      >
        Confidence
      </Text>
      <Text
        className={clsx(styles.confidencePercent, { [styles.isFaded]: fade })}
        component="span"
        variant="subtitle1"
      >
        {confidence}
      </Text>
      <span className={styles.confidenceOverlay} style={confidenceStyle} />
      <span
        className={clsx(styles.confidenceGradient, { [styles.isFaded]: fade })}
      />
    </div>
  );
};
