import type { UserProfileResponseRecord } from '@on3/api';
import { On3Avatar } from 'components/Image/On3Avatar';
import { Twitter } from 'components/Svg/Twitter';
import { TextLink } from 'components/UI/Link/TextLink';
import { Text } from 'components/UI/Typography/Text';
import Link from 'next/link';

import styles from './FeaturedWriter.module.scss';

export const FeaturedWriter = ({
  name,
  niceName,
  twitterHandle,
  profilePicture,
}: UserProfileResponseRecord) => {
  return (
    <div className={styles.writerContainer}>
      <On3Avatar
        altText={`${name} Avatar`}
        className={styles.authorImage}
        height={50}
        radiusFive
        source={profilePicture?.url}
        width={50}
      />
      <div className={styles.contentContainer}>
        <div className={styles.authorWrapper}>
          <TextLink
            color="primary"
            href={`/user/${niceName}/`}
            underline="none"
            variant="subtitle2"
          >
            {name}
          </TextLink>
        </div>

        <div className={styles.twitterHandle}>
          <Twitter />
          {twitterHandle && (
            <Link
              className={styles.twitterText}
              href={`https://twitter.com/${twitterHandle}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Text color="primary" variant="caption">
                {twitterHandle}
              </Text>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
