import { SVGProps } from 'react';

const SvgCheck = ({ fill, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    fill={fill || '#00C853'}
    height="1em"
    viewBox="0 0 25 25"
    width="1em"
    {...props}
  >
    <path d="M10.796 14.303 7.76 11.268l-1.51 1.51 4.531 4.532 8.55-8.55-1.51-1.51-7.025 7.053Z" />
  </svg>
);

export default SvgCheck;
