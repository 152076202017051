import type { RpmPickList } from '@on3/api';
import { On3IconCheck, On3IconFlippedPrediction, On3IconX } from '@on3/icons';
import { useSite } from '@on3/ui-lib/index';
import { formatCurrency } from '@on3/ui-lib/utils/formatters';
import clsx from 'clsx';
import { ConfidenceBar } from 'components/Db/Profile/Rpm/ConfidenceBar';
import { On3Avatar } from 'components/Image/On3Avatar';
import { TrendArrow } from 'components/TrendArrow/TrendArrow';
import { AthleteVerified } from 'components/UI/AthleteVerified/AthleteVerified';
import { TextLink } from 'components/UI/Link/TextLink';
import { StarRating } from 'components/UI/StarRating/StarRating';
import { Text } from 'components/UI/Typography/Text';
import { format } from 'date-fns';
import { useMemo } from 'react';

import styles from './RpmModuleItem.module.scss';

const changeLabel = {
  ConfidenceChange: 'Change in confidence level',
  NewLeader: 'New leader',
  Pick: '',
  PercentChange: 'Change in rpm',
};

export const RpmModuleItem = ({ prediction }: { prediction: RpmPickList }) => {
  const { pick, player } = prediction || {};
  const {
    name,
    nilValue,
    rating,
    slug,
    height,
    weight,
    defaultAssetUrl,
    athleteVerified,
  } = player || {};
  const {
    dateAdded,
    expert,
    confidence,
    previousConfidence,
    previousDateAdded,
    type,
    articleLink,
    topTeams,
    organization,
    flippedFromOrganization,
  } = pick || {};
  const isPick = type === 'Pick';
  const isConfidenceChange = type === 'ConfidenceChange';

  const newLeader = topTeams?.[0];
  const oldLeader = topTeams?.[1];
  const hasConfidence = confidence !== null && confidence !== undefined;
  const hasPreviousConfidence =
    previousConfidence !== null &&
    previousConfidence !== undefined &&
    isConfidenceChange;

  const predictionDate = useMemo(() => {
    if (dateAdded) {
      const newLocal = format(new Date(dateAdded), 'M/dd/yy hh:mm a');
      const [date, ...time] = newLocal.split(' ');

      return `${date}\n${time.join('\xa0')}`;
    }

    return '-';
  }, [dateAdded]);
  const previousDate = useMemo(() => {
    if (previousDateAdded) {
      const newLocal = format(new Date(previousDateAdded), 'M/dd/yy hh:mm a');
      const [date, ...time] = newLocal.split(' ');

      return `${date}\n${time.join('\xa0')}`;
    }

    return '-';
  }, [previousDateAdded]);

  const typeOfChangeValue: keyof typeof styles = useMemo(() => {
    if (hasPreviousConfidence && hasConfidence) {
      return previousConfidence > confidence ? 'negative' : 'positive';
    }

    return 'neutral';
  }, [confidence, hasConfidence, hasPreviousConfidence, previousConfidence]);

  const rankChange: keyof typeof styles = useMemo(() => {
    if (newLeader?.currentPercentage && newLeader.previousPercentage) {
      return newLeader?.previousPercentage > newLeader?.currentPercentage
        ? 'negative'
        : 'positive';
    }

    return 'neutral';
  }, [newLeader?.currentPercentage, newLeader?.previousPercentage]);
  const sport = 'football';
  const { defaultYears } = useSite();
  const { footballRecruitingYear, nonFootballRecruitingYear } = defaultYears;
  const recruitingYear =
    sport === 'football' ? footballRecruitingYear : nonFootballRecruitingYear;
  const showFlippedOrg = flippedFromOrganization?.slug;
  const showOrg = organization?.slug;
  const formattedNilValue = nilValue ? (
    <span className={styles.nilValuation}>{formatCurrency(nilValue)}</span>
  ) : (
    <span className={styles.valuationUndisclosed}>undisclosed</span>
  );
  const recruitHref = (slug && `/db/${slug}/industry-comparison/`) || '';
  const isNewLeader = type === 'NewLeader';
  const isRankChange = type === 'PercentChange';
  const isPickFinal = pick?.correct != null && !isNewLeader && !isRankChange;
  const typeOfChangeLabel = type && changeLabel[type];
  const enteredPortal =
    player?.commitStatus?.transferEntered ||
    player?.commitStatus?.transferredAsset;

  return (
    <li
      className={clsx(styles.predicitionItem, {
        [styles.hasLevelChange]: isConfidenceChange,
        [styles.hasRankChange]: isNewLeader || isRankChange,
      })}
    >
      {typeOfChangeLabel && (
        <Text className={styles.typeOfChange} variant="subtitle1">
          {typeOfChangeLabel}
        </Text>
      )}
      {(!hasPreviousConfidence || isNewLeader) && (
        <Text className={styles.dateTimeMobile} variant="caption">
          {predictionDate}
        </Text>
      )}
      <On3Avatar
        altText={`${name} Avatar` || ''}
        className={styles.avatar}
        height={50}
        source={defaultAssetUrl}
        width={50}
      />

      <div className={styles.playerInfoWrapper}>
        <div className={styles.playerNameTransfer}>
          <TextLink className={styles.playerNameLink} href={`/db/${slug}/`}>
            <span>{name}</span>
            {athleteVerified && <AthleteVerified />}
          </TextLink>
          {enteredPortal && (
            <span className={clsx(styles.transferTag)}>Transfer</span>
          )}
        </div>
        <Text className={styles.playerVitals} component="div" variant="caption">
          <span className={styles.position}>{rating?.positionAbbr ?? '-'}</span>
          <span className={styles.height}>{height ?? '-'}</span>
          <span>{weight ?? '-'}</span>
        </Text>
        <StarRating
          href={recruitHref}
          isConsensus
          rating={rating?.rating}
          small
          stars={rating?.stars}
        />
      </div>
      <div className={styles.nilContainer}>
        <Text className={styles.nilLabel}>NIL Value:</Text>
        <Text className={styles.nilValue}>{formattedNilValue}</Text>
      </div>
      {(isPick || isConfidenceChange) && (
        <div className={styles.predictionWrapper}>
          {organization?.asset && (
            <div
              className={clsx(styles.logosContainer, {
                [styles.isFlipped]: showFlippedOrg,
              })}
            >
              <div className={styles.flippedPickIcons}>
                {showFlippedOrg && (
                  <>
                    <TextLink
                      className={styles.teamLogoLink}
                      href={`/college/${flippedFromOrganization.slug}/${sport}/${recruitingYear}/industry-comparison-commits/`}
                      underline="none"
                    >
                      <On3Avatar
                        className={styles.flippedFromLogo}
                        domain={flippedFromOrganization?.asset?.domain}
                        height={30}
                        source={flippedFromOrganization?.asset?.source}
                        teamAvatar
                        type={flippedFromOrganization?.asset?.type}
                        width={30}
                      />
                    </TextLink>
                    <On3IconFlippedPrediction
                      className={styles.predictionSwappedIcon}
                    />
                  </>
                )}
                {showOrg && (
                  <TextLink
                    className={styles.teamLogoLink}
                    href={`/college/${organization.slug}/${sport}/${recruitingYear}/industry-comparison-commits/`}
                    underline="none"
                  >
                    <On3Avatar
                      domain={organization?.asset?.domain}
                      height={30}
                      source={organization?.asset?.source}
                      teamAvatar
                      type={organization?.asset?.type}
                      width={30}
                    />
                  </TextLink>
                )}
              </div>
              {articleLink && (
                <TextLink
                  className={styles.premiumIntelContainer}
                  href={articleLink}
                  rel="noreferrer"
                  target="_blank"
                  underline="none"
                >
                  Intel
                </TextLink>
              )}
            </div>
          )}
        </div>
      )}
      {(isPick || isConfidenceChange) && (
        <div className={styles.predictorWrapper}>
          <On3Avatar
            altText={`${name} Avatar` || ''}
            className={styles.predictorAvatar}
            domain={expert?.profilePictureResponse?.domain}
            height={30}
            source={expert?.profilePictureResponse?.source}
            width={30}
          />
          <div className={styles.predictorInfoWrapper}>
            <TextLink
              className={styles.predictorText}
              href={`/user/${expert?.niceName}/predictions/`}
              variant="h5"
            >
              {expert?.name ?? '-'}
            </TextLink>

            {expert?.jobTitle && (
              <Text className={styles.jobTitle}>{expert?.jobTitle}</Text>
            )}
          </div>
          {isPickFinal && pick?.correct && (
            <On3IconCheck
              className={clsx(styles.correctPick, styles.mobile)}
              color="inherit"
              height={24}
              width={24}
            />
          )}
          {isPickFinal && !pick?.correct && (
            <On3IconX
              className={clsx(styles.incorrectPick, styles.mobile)}
              color="inherit"
              height={24}
              width={24}
            />
          )}
        </div>
      )}

      {hasConfidence && (
        <div
          className={clsx(styles.confidenceContainer, {
            [styles.hasLevelChange]: isConfidenceChange,
          })}
        >
          {hasPreviousConfidence && !showFlippedOrg && (
            <div className={styles.confidenceWithTrendContainer}>
              <div className={styles.confidenceDateContainer}>
                <ConfidenceBar
                  confidence={previousConfidence}
                  fade
                  variant="condensedOnly"
                />
                <Text className={styles.dateTime} variant="caption">
                  {previousDate}
                </Text>
              </div>
              <TrendArrow className={clsx(styles[typeOfChangeValue])} />
            </div>
          )}

          <div className={styles.confidenceDateContainer}>
            <ConfidenceBar confidence={confidence} variant="condensedOnly" />
            <Text className={styles.dateTime} variant="caption">
              {predictionDate}
            </Text>
          </div>
        </div>
      )}

      {isNewLeader && (
        <div className={styles.leadersWrapper}>
          <div className={styles.typeContainerDesktop}>
            {typeOfChangeLabel && (
              <Text className={styles.typeOfChangeDesktop} variant="subtitle1">
                {typeOfChangeLabel}
              </Text>
            )}
            <Text className={styles.dateTime} variant="caption">
              {predictionDate}
            </Text>
          </div>
          <div className={styles.newLeaderContainer}>
            <TextLink
              href={`/college/${newLeader?.organization?.slug}/${sport}/${recruitingYear}/industry-comparison-commits/`}
              underline="none"
            >
              <On3Avatar
                domain={newLeader?.organization?.asset?.domain}
                height={30}
                source={newLeader?.organization?.asset?.source}
                teamAvatar
                type={newLeader?.organization?.asset?.type}
                width={30}
              />
            </TextLink>
            <div className={styles.currentRank}>
              <Text className={styles.rank} variant="subtitle1">
                #{newLeader?.currentRank}
              </Text>
              {newLeader?.currentPercentage && (
                <Text className={styles.percent}>
                  {newLeader?.currentPercentage.toFixed(1)}%
                </Text>
              )}
            </div>
          </div>
          <div className={styles.oldLeaderContainer}>
            <Text className={styles.rank} variant="subtitle1">
              #{oldLeader?.currentRank}
            </Text>
            <TextLink
              href={`/college/${oldLeader?.organization?.slug}/${sport}/${recruitingYear}/industry-comparison-commits/`}
              underline="none"
            >
              <On3Avatar
                domain={oldLeader?.organization?.asset?.domain}
                height={25}
                source={oldLeader?.organization?.asset?.source}
                teamAvatar
                type={oldLeader?.organization?.asset?.type}
                width={25}
              />
            </TextLink>
            {oldLeader?.currentPercentage && (
              <span className={styles.percent}>
                {oldLeader?.currentPercentage.toFixed(1)}%
              </span>
            )}
          </div>
        </div>
      )}
      {isRankChange && (
        <div className={styles.leadersWrapper}>
          <div className={styles.typeContainerDesktop}>
            {typeOfChangeLabel && (
              <Text className={styles.typeOfChangeDesktop} variant="subtitle1">
                {typeOfChangeLabel}
              </Text>
            )}
            <div className={styles.rankDesktopLogoContainer}>
              <TextLink
                className={styles.rankChangeLogoLinkDesktop}
                href={`/college/${newLeader?.organization?.slug}/${sport}/${recruitingYear}/industry-comparison-commits/`}
                underline="none"
              >
                <On3Avatar
                  domain={newLeader?.organization?.asset?.domain}
                  height={50}
                  source={newLeader?.organization?.asset?.source}
                  teamAvatar
                  type={newLeader?.organization?.asset?.type}
                  width={50}
                />
              </TextLink>
              <Text className={styles.dateTime} variant="caption">
                {predictionDate}
              </Text>
            </div>
          </div>
          <div className={clsx(styles.newLeaderContainer, styles.rankChange)}>
            <TextLink
              className={styles.rankChangeLogoLinkMobile}
              href={`/college/${newLeader?.organization?.slug}/${sport}/${recruitingYear}/industry-comparison-commits/`}
              underline="none"
            >
              <On3Avatar
                domain={newLeader?.organization?.asset?.domain}
                height={40}
                source={newLeader?.organization?.asset?.source}
                teamAvatar
                type={newLeader?.organization?.asset?.type}
                width={40}
              />
            </TextLink>
            <div className={styles.ranksContainer}>
              <div className={styles.oldRank}>
                <Text className={styles.rank} variant="subtitle1">
                  #{newLeader?.previousRank}
                </Text>

                {newLeader?.previousPercentage && (
                  <span className={styles.percent}>
                    {newLeader?.previousPercentage.toFixed(1)}%
                  </span>
                )}
                <TrendArrow className={clsx(styles[rankChange])} />
              </div>
              <div className={styles.currentRank}>
                <Text className={styles.rank} variant="subtitle1">
                  #{newLeader?.currentRank}
                </Text>
                {newLeader?.currentPercentage && (
                  <Text className={styles.percent}>
                    {newLeader?.currentPercentage.toFixed(1)}%
                  </Text>
                )}
              </div>
            </div>
          </div>
          <div className={styles.oldLeaderContainer}>
            <Text className={styles.rank} variant="subtitle1">
              #{oldLeader?.currentRank}
            </Text>
            <TextLink
              href={`/college/${oldLeader?.organization?.slug}/${sport}/${recruitingYear}/industry-comparison-commits/`}
              underline="none"
            >
              <On3Avatar
                className={styles.oldLeaderLogo}
                domain={oldLeader?.organization?.asset?.domain}
                height={25}
                source={oldLeader?.organization?.asset?.source}
                teamAvatar
                type={oldLeader?.organization?.asset?.type}
                width={25}
              />
            </TextLink>
            {oldLeader?.currentPercentage && (
              <span className={styles.percent}>
                {oldLeader?.currentPercentage.toFixed(1)}%
              </span>
            )}
          </div>
        </div>
      )}
      {isPickFinal && pick?.correct && (
        <On3IconCheck
          className={styles.correctPick}
          color="inherit"
          height={24}
          width={24}
        />
      )}
      {isPickFinal && !pick?.correct && (
        <On3IconX
          className={styles.incorrectPick}
          color="inherit"
          height={24}
          width={24}
        />
      )}
    </li>
  );
};
