import type { SVGProps } from 'react';

interface IOn3LogoAltProps extends SVGProps<SVGElement> {
  className?: string;
}
export const On3LogoAlt = ({ className, fill, width }: IOn3LogoAltProps) => {
  return (
    <svg
      className={className}
      fill={fill ?? '#6F747B'}
      height="20"
      viewBox="0 0 44 20"
      width={width || '44'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="st0"
        d="M13.3,13.3c-0.2,0.8-0.6,1.4-1.1,1.9s-1.1,1-1.8,1.2c-0.8,0.3-1.6,0.4-2.7,0.4H5.9c-1,0-1.9-0.2-2.7-0.4
    c-0.8-0.3-1.4-0.7-1.8-1.2c-0.5-0.5-0.8-1.2-1.1-1.9C0.1,12.6,0,11.7,0,10.8V8.6c0-1,0.1-1.8,0.3-2.5c0.2-0.8,0.6-1.4,1.1-2
    c0.5-0.5,1.1-1,1.8-1.2C4,2.5,4.9,2.4,5.9,2.4h1.8c1,0,1.9,0.2,2.7,0.4c0.8,0.3,1.4,0.7,1.8,1.2c0.5,0.5,0.8,1.2,1.1,2
    c0.2,0.8,0.3,1.6,0.3,2.5v2.2C13.7,11.7,13.5,12.6,13.3,13.3z M3.1,14.1c0.5,0.6,1.4,1,2.6,1H8c1.2,0,2.1-0.3,2.6-1
    c0.5-0.6,0.8-1.6,0.8-2.8V8c0-1.3-0.3-2.2-0.8-2.8C10.1,4.5,9.2,4.3,8,4.3H5.7c-1.2,0-2.1,0.3-2.6,0.9C2.5,5.8,2.2,6.7,2.2,8v3.3
    C2.2,12.6,2.5,13.5,3.1,14.1z M25.5,17.2c-0.6,0-1-0.2-1.4-0.5c-0.3-0.3-0.6-0.7-1-1.2l-4.6-7.8c-0.1-0.2-0.2-0.3-0.2-0.4
    c0,0,0,0-0.1,0s-0.1,0-0.1,0s0,0.2,0,0.3V17H16V7.7c0-0.7,0.2-1.3,0.5-1.8s0.9-0.8,1.7-0.8c0.5,0,1,0.2,1.4,0.5
    c0.3,0.3,0.7,0.8,1,1.4l4.5,7.6c0.1,0.1,0.2,0.2,0.2,0.3l0.1,0.1c0.1,0,0.1,0,0.1,0s0-0.1,0-0.2V5.4h2.1v9.4c0,0.7-0.2,1.3-0.5,1.7
    C26.8,16.9,26.2,17.2,25.5,17.2z M40.1,0H34c-2,0-3.7,1.7-3.7,3.9v12.3c0,2.1,1.6,3.9,3.7,3.9h6.2c2,0,3.7-1.8,3.7-3.9V3.9
    C43.8,1.8,42.2,0,40.1,0z M39.5,10.3c1,0.7,1.6,1.9,1.6,3.1c0,0.2,0,0.5-0.1,0.8c-0.3,1.6-1.7,2.9-3.4,3h-0.2c-0.1,0-0.2,0-0.3,0
    h-4.2v-2.9h4.6c0.5-0.1,0.8-0.4,0.9-0.8c0-0.1,0-0.2,0-0.2c0-1.1-1.9-1.9-2.7-2.6V9.4c0.8-0.8,2.7-1.6,2.7-2.6c0-0.1,0-0.2,0-0.2
    c-0.1-0.4-0.4-0.8-0.8-0.8h-4.7V2.9h4.2c0.1,0,0.2,0,0.3,0s0.2,0,0.3,0c1.6,0.1,3.1,1.4,3.4,3c0,0.2,0.1,0.5,0.1,0.8
    c0,1.3-0.6,2.4-1.6,3.1L39.1,10L39.5,10.3z"
      />
    </svg>
  );
};
